<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">方案详情</h3>
    <div class="detail">
      <h4 class="title">基本信息</h4>
      <div class="desc">
        <span>实验编号：{{ activeInfo.number }}</span>
        <span>申请日期：{{ activeInfo.startTime | formatDay }}</span>
        <p>实验名称：{{ activeInfo.name }}</p>
        <p>实验摘要：{{ activeInfo.detail.summary }}</p>
        <p>实验目的：{{ activeInfo.detail.aim }}</p>
      </div>
      <h4 class="title">项目成员</h4>
      <div class="desc">
        <span>负责人：{{ memberPrincipal.name }}</span>
        <span>负责人电话：{{ memberPrincipal.phone }}</span>
        <span>负责人邮箱：{{ memberPrincipal.email }}</span>
      </div>
      <el-table
          :data="memberList"
          border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          max-height="250">
        <el-table-column label="项目组成员姓名" width="180" prop="name">
        </el-table-column>
        <el-table-column label="相关专业证书编号" min-width="100" show-overflow-tooltip prop="certNum">
        </el-table-column>
        <el-table-column label="工作内容" show-overflow-tooltip prop="jobContent">
        </el-table-column>
        <el-table-column label="电话" prop="phone">
        </el-table-column>
        <el-table-column label="邮箱" prop="email">
        </el-table-column>
      </el-table>
      <h4 class="title">实验动物</h4>
      <div class="desc">
        <p>
          <span>品系：{{ activeInfo.strain }}</span>
          <span>来源：{{ activeInfo.aniOrder.aniSupply.supplier }}</span>
          <span>许可证：{{ activeInfo.aniOrder.aniSupply.prodCert }}</span>
        </p>
        <p>
          <span>需要笼舍数量：{{ activeInfo.cageNum }}</span>
          <span>申请笼舍数量：{{ activeInfo.detail.applyCageNum }}</span>
        </p>
        <p>
          <span>雌性周龄：{{ female.weekDays }}周</span>
          <span>雌性体重：{{ female.weight }}g</span>
          <span>雌性数量：{{ female.count }}</span>
        </p>
        <p>
          <span>雄性周龄：{{ male.weekDays }}周</span>
          <span>雄性体重：{{ male.weight }}g</span>
          <span>雄性数量：{{ male.count }}</span>
        </p>
        <p>等级：{{ activeInfo.aniOrder.grade == 0 ? '普通' : activeInfo.aniOrder.grade == 1 ? 'SPF' : '清洁级' }}</p>
      </div>
      <h4 class="title">实验时间</h4>
      <div class="desc">
        <span>计划开始时间：{{ activeInfo.startTime  | formatDay }}</span>
<!--        <span>计划实验周期：{{ activeInfo.period }}周</span>-->
        <span>计划结束时间：{{ activeInfo.period  | formatDay }}</span>
      </div>
      <h4 class="title">实验备注</h4>
      <div class="desc">
        <p>实验备注：{{ activeInfo.detail.remark }}</p>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "plan",
  props: ["id"],
  data() {
    return {
      activeInfo: {
        detail: {},
        aniOrder: {aniSupply: {}},
      },
      memberList: [],
      memberPrincipal: {},
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  async mounted() {
    let that = this;
    await that.$get("/subject/detail/" + this.id).then((res) => {
      if (res.status == 200) {
        that.activeInfo = res.data
        // 成员
        res.data.memberList.forEach(item => {
          if (item.isPrincipal == 1) {
            this.memberPrincipal = item
          } else {
            this.memberList.push(item)
          }
        })
        // 雌雄性
        res.data.aniOrder.aniOrderDetailList.forEach(obj => {
          if (obj.gender == 0) {
            this.female = obj
          } else {
            this.male = obj
          }
        })
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
